<template>
    <v-dialog v-model="value" max-width="600" @click:outside="close()">
        <v-card v-if="value">
            <v-card-title class="headline"> ##&en Add Calibration ##&hu Kalibráció hozzáadása ## </v-card-title>

            <v-card-text class="text-left">
                <v-select
                    v-if="parameters.keys.length > 1"
                    :items="[
                        { text: this.parameters.keys.join(' ##&en and ##&hu és ## '), value: false },
                        { text: this.parameters.keys.join(' ##&en or ##&hu vagy ## '), value: true },
                    ]"
                    label="##&en Evaluation ##&hu Kiértékelés ##"
                    v-model="logic"
                    @change="update()"
                >
                </v-select>
                <v-select :items="algos" label="##&en Algorithm ##&hu Algoritmus ##" v-model="algo" @change="update()"></v-select>
                <br />
                <i> {{ str }} </i><br /><br />
                <v-text-field v-model="name" label="##&en Name ##&hu Elnevezés ##" />
                <v-textarea v-model="comment" label="##&en Comments ##&hu Megjegyzések ##" />
                <!--pre v-if="algo">{{ getParameters() }}</pre-->
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn :disabled="addDisabled()" text @click="add()"> <v-icon>mdi-plus</v-icon> </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
// TODO escape press
// [Vue warn]: Avoid mutating a prop directly since the value will be overwritten whenever the parent component re-renders. Instead, use a data or computed property based on the prop's value. Prop being mutated: "value"

export default {
    name: "calibration-dialog",
    props: ["value", "parameters"],
    data() {
        return {
            str: "",
            name: null,
            comment: "",
            payload: {},
            algo: null,
            detector: null,
            algos: ß.ALGOS,
            hint: "",
            logic: false,
        };
    },
    mounted() {
        //this.update();
    },
    methods: {
        update() {
            if (!this.parameters) return;

            this.payload = { ...this.parameters, ...{ algo: this.algo } };

            let b = " & ";
            if (this.logic) b = " V ";

            this.str = this.parameters.keys.join(b) + " " + this.algo + " m:" + this.parameters.min + " M:" + this.parameters.max + " T:" + this.parameters.timespan + "s";

            this.hint = ß.ALGOS.filter((e) => e.value === this.algo)[0].hint;

            // tolarance = Math.abs(Math.abs(this.parameters.min) - Math.abs(this.parameters.max));
        },

        getParameters() {
            return JSON.stringify({ ...this.parameters, ...{ algo: this.algo, detector: this.detector, name: this.name } }, null, 4);
        },
        close() {
            this.$emit("input", false);
        },
        add() {
            if (!this.name) this.name = this.str;
            else if (!this.comment) this.comment = this.str;

            const parameters = { ...this.parameters, ...{ algo: this.algo, name: this.name, comment: this.comment } };

            this.$socket.client.emit("add_calibration", parameters, () => {
                this.$emit("input", false);
            });
        },
        addDisabled() {
            if (!this.algo) return true;
            if (!this.name) return true;
            if (this.name.length < 2) return true;
            // this.name in calibrations
            if (this.$store.getters["database/getDocumentByName"]("calibrations", this.name) !== null) return true;
            return false;
        },
    },
};
</script>
