<template>
    <v-container fill-height dark>
        <v-layout>
            <v-row justify="center" no-gutters>
                <v-col md="6" lg="5" xl="3" cols="12" class="pa-5 desc-cols" v-for="(value, name, index) in $store.state.timelines" :key="index">
                    <v-card class="pa-2" style="max-width: 360px; width: 360px; margin: auto">
                        <div class="pa-5 img-cont">
                            <v-btn class="mx-2 ma-3" fab color="primary" @click="show(name)"><v-icon>mdi-chart-timeline</v-icon></v-btn>
                        </div>
                        <v-card-title class="text-center text-uppercase" style="display: block">{{ name }}</v-card-title>
                    </v-card>
                </v-col>
            </v-row>
        </v-layout>
    </v-container>
</template>
<script>
export default {
    name: "timelines",
    route: { path: "/timelines", icon: "mdi-chart-timeline", navigation: "timelines" },
    methods: {
        show(s) {
            this.$router.push("/timeline/" + s);
        },
    },
};
</script>

<style scoped></style>
