<template>
    <div id="chart_component">
        <v-card style="min-width: 300px; max-width: 1200px; margin: auto; position: relative">
            <v-simple-table dense width="300">
                <template v-slot:default>
                    <tbody>
                        <tr>
                            <td class="text-right">
                                <h2>{{ name }}</h2>
                            </td>
                            <td class="text-left"><v-checkbox v-model="checkbox" label="##&en Details ##&hu Részletek ##"></v-checkbox></td>
                        </tr>
                        <tr v-if="checkbox">
                            <td class="text-right">{{ local_memorydata.length }}</td>
                            <td class="text-left">##&en data packages ##&hu adatcsomag ##</td>
                        </tr>
                        <tr v-if="checkbox">
                            <td class="text-right">{{ (sensor_socket.connected && "#connected") || "#disconnected" }}</td>
                            <td class="text-left">##&en Connection ##&hu Kapcsolat ##</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-simple-table fixed-header dense width="300">
                <thead>
                    <tr>
                        <th class="text-right">##&en KEY ##&hu KULCS ##</th>
                        <th class="text-left">##&en VALUE ##&hu ÉRTÉK ##</th>
                        <th class="text-right">GRAPH</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(value, key, i) in local_latestdata" v-if="checkbox || latestdataKeyEnabled(key, value)" :key="i">
                        <td width="60%" class="text-right">
                            <b>{{ value }}</b>
                        </td>
                        <td width="30%" class="text-left">{{ key }}</td>
                        <td width="10%">
                            <v-chip v-if="latestdataKeyEnabled(key, value)" :color="get_color(key, selection[key])" x-small @click="show(key)"></v-chip>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
            <v-container fluid>
                <v-row>
                    <v-col cols="12">
                        <v-slider v-model="timespan" min="1" :max="max_timespan" label="T" class="align-center" color="black" @change="adapt()">
                            <template v-slot:append>
                                <v-text-field v-model="timespan" class="mt-0 pt-0" type="number" label="seconds" style="width: 60px"></v-text-field>
                            </template>
                        </v-slider>
                    </v-col>

                    <v-col cols="12" v-if="!(min === 0 && max === 1)">
                        <v-slider v-model="maxM" :min="min" :max="max" label="M" class="align-center" color="blue" @change="adapt()">
                            <template v-slot:append>
                                <v-text-field v-model="maxM" class="mt-0 pt-0" type="number" :label="'max ' + unit" style="width: 60px"></v-text-field>
                            </template>
                        </v-slider>
                    </v-col>

                    <v-col cols="12" v-if="!(min === 0 && max === 1)">
                        <v-slider v-model="minm" :min="min" :max="max" label="m" class="align-center" color="red" @change="adapt()">
                            <template v-slot:append>
                                <v-text-field v-model="minm" class="mt-0 pt-0" type="number" :label="'min ' + unit" style="width: 60px"></v-text-field>
                            </template>
                        </v-slider>
                    </v-col>

                    <v-col cols="12">
                        <v-btn v-if="freezed" @click="unfreeze()" title="Unfreeze">
                            <v-icon>mdi-play</v-icon>
                        </v-btn>
                        <v-btn v-if="!freezed" @click="freeze()" title="Freeze">
                            <v-icon>mdi-stop</v-icon>
                        </v-btn>
                        <v-btn @click="addCalibration()" title="on values" :disabled="noSelection()">
                            <v-icon>mdi-plus-box</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
            <div id="beacon-d3" class="mb-9"></div>
        </v-card>
        <calibration-dialog v-model="calibration_dialog" :parameters="parameters" :key="calibration_dialog_key" />
    </div>
</template>

<script>
//import * as d3 from 'd3';
//globalThis.d3 = d3;

import beacon_d3 from "./beacon.d3.js";

import { io } from "socket.io-client";
const sensor_socket = io("/sensor", { forceNew: true });

import CalibrationDialog from "@/calibration-dialog.vue";

// vue action
export default {
    name: "beacon",
    route: { path: "/beacon/:id?", icon: "mdi-hvac" },
    components: { CalibrationDialog },
    data() {
        return {
            name: "Unregistered",
            checkbox: false,
            // d3 stuff
            beacon_d3: null,
            graph: {
                min: -2000,
                max: 2000,
                graphs: [],
            },
            // local stuff
            id: this.$route.params.id || "emulator",
            local_memorydata: [],
            local_latestdata: {},
            graph_series: [],
            graph_visual: [],
            // selection key name, value bool
            selection: {},
            timespan: 300, //Math.round(ß.DATAMEMORY_TIMESPAN / 2),
            max_timespan: 600, //ß.DATAMEMORY_TIMESPAN,
            min: -2000,
            max: 2000,
            minm: -2000,
            maxM: 2000,
            unit: "mG",
            sensor_socket: sensor_socket,
            freezed: false,
            freeze_datamemory: null,
            freezdate: null,
            calibration_dialog: false,
            calibration_dialog_key: 0,
            parameters: null,
            group: "acceleration",
        };
    },
    mounted() {
        this.setup();
        this.open_socket();

        this.beacon_d3 = beacon_d3(this);

        setInterval(this.purge, 1000);
    },
    methods: {
        // === DATA HANDLING SECTION ===
        setup() {
            if (!this.id) return;
            //console.log("sensor:", this.id, this.local_latestdata.id === this.id);
            if (this.id !== this.$route.params.id) this.$router.push("/sensor/" + this.id);
            this.selection = {};
            this.local_memorydata.length = 0;
            this.local_latestdata = {};

            sensor_socket.emit("sensor_select", this.id, (payload) => {
                if (payload.name) this.name = payload.name;
                console.log(this.id, payload);
                if (payload.memorydata) this.local_memorydata = payload.memorydata;
                else this.local_memorydata = [];
                if (payload.latestdata) this.local_latestdata = payload.latestdata;

                this.adapt();
            });
        },
        open_socket() {
            // client-side
            sensor_socket.on("connect", () => {
                console.log("sensor-socket connect");
            });

            sensor_socket.on("disconnect", () => {
                console.log("sensor-socket disconnect");
            });

            // sensor data

            sensor_socket.on("update_beacon", this.update_beacon);

            //sensor_socket.on("add_to_memorydata", this.add_to_memorydata);
            //sensor_socket.on("add_to_latestdata", this.add_to_latestdata);
        },

        update_beacon({ memorydata, latestdata }) {
            console.log("update_beacon", memorydata);

            //this.local_memorydata.length = 0;
            //memorydata.forEach(i => this.local_memorydata.push(i));

            this.local_memorydata = memorydata;

            this.local_latestdata = { ...this.local_latestdata, ...latestdata };

            this.purge();
            this.adapt();
        },

        add_to_memorydata(data) {
            // console.log(data);
            this.local_memorydata.push(data);
            // the latest data shall have all fields we ever recieved
            this.local_latestdata = { ...this.local_latestdata, ...data };

            if (Object.keys(this.selection).length === 0)
                if (this.local_latestdata.accelerationX !== undefined || this.local_latestdata.accelerationY !== undefined || this.local_latestdata.accelerationZ !== undefined)
                    this.selection = { accelerationX: true, accelerationY: true, accelerationZ: true };
                else this.selection = { rssi: true };

            this.purge();
            this.adapt();
        },
        add_to_latestdata(data) {
            // the latest data shall have all fields we ever recieved
            this.local_latestdata = { ...this.local_latestdata, ...data };
        },
        purge() {
            const time_threshold = new Date().getTime() - 600 * 1000; // ß.DATAMEMORY_TIMESPAN * 1000
            this.local_memorydata = this.local_memorydata.filter((e) => e.timestamp > time_threshold);
        },

        // === GRAPH HANDLING SECTION ===
        adapt() {
            if (Object.keys(this.selection).length === 0)
                if (this.local_latestdata.accelerationX !== undefined || this.local_latestdata.accelerationY !== undefined || this.local_latestdata.accelerationZ !== undefined)
                    this.selection = { accelerationX: true, accelerationY: true, accelerationZ: true };
                else this.selection = { rssi: true };
            // data-format adatper for the d3 based graph
            const graphs = [];
            const datamemory = this.freeze_datamemory || this.local_memorydata;
            // local_memorydata is an array, that has objects with timestamp and datafields
            //console.log( this.local_memorydata );
            const selection = Object.keys(this.selection).filter((e) => this.selection[e] === true);
            for (const p of selection) {
                const sensorprop = ß.SENSOR_PROPERTIES[p] || {};
                let o = {};
                o.color = sensorprop.color || "#f00";
                o.name = p;
                o.data = datamemory.filter((e) => e[p] !== undefined).map((e) => [e.timestamp, e[p]]);
                graphs.push(o);
            }

            if (!(this.min === 0 && this.max === 1)) {
                graphs.push({
                    name: "min-line",
                    color: "#F00",
                    data: this.minm,
                });

                graphs.push({
                    name: "max-line",
                    color: "#00F",
                    data: this.maxM,
                });
            }

            this.graph.graphs = graphs;
        },
        show(k) {
            let groupchange = false;
            if (ß.SENSOR_PROPERTIES[k].group !== this.group) groupchange = true;
            this.group = ß.SENSOR_PROPERTIES[k].group;

            // in the same group, invert
            for (const i in ß.SENSOR_PROPERTIES)
                if (ß.SENSOR_PROPERTIES[i].group === ß.SENSOR_PROPERTIES[k].group) this.selection[k] = !this.selection[k];
                // other groups set to false
                else this.selection[i] = false;

            if (!groupchange) return;

            if (ß.SENSOR_PROPERTIES[k].unit) this.unit = ß.SENSOR_PROPERTIES[k].unit;
            if (ß.SENSOR_PROPERTIES[k].min === undefined || ß.SENSOR_PROPERTIES[k].max === undefined) return;
            this.min = ß.SENSOR_PROPERTIES[k].min;
            this.max = ß.SENSOR_PROPERTIES[k].max;
            this.graph.min = this.min;
            this.graph.max = this.max;
            this.adapt();
        },

        // === CALIBRATIONS ===
        noSelection() {
            for (const i in this.selection) if (this.selection[i]) return false;
            return true;
        },
        addCalibration() {
            const o = {};
            o.sensor = this.id;

            o.keys = [];
            for (const i in this.selection) if (this.selection[i]) o.keys.push(i);
            o.min = this.minm;
            o.max = this.maxM;
            o.timespan = this.timespan;

            this.parameters = o; //JSON.stringify(o, null, 4);

            this.calibration_dialog_key++;
            this.calibration_dialog = true;
        },
        // -- freezer --
        freeze() {
            this.freezed = true;
            this.freezdate = new Date();
            this.freeze_datamemory = Array.from(this.local_memorydata);
            this.beacon_d3.render();
        },
        unfreeze() {
            this.freezed = false;
            this.freezdate = null;
            this.freeze_datamemory = null;
        },

        get_color(str, e) {
            if (e) return ß.SENSOR_PROPERTIES[str].color;
            return "#ddd";
        },
        latestdataKeyEnabled(k, v) {
            return ß.SENSOR_PROPERTIES[k] !== undefined;
        },

        routeChange() {
            this.id = this.$route.params.id;
            this.setup();
        },
    },
    watch: {
        "$route.params.id"(to, from) {
            this.routeChange();
        },
        minm(to, from) {
            if (this.minm > this.maxM) this.maxM = this.minm;
            this.graph.graphs.forEach((e) => {
                if (e.name === "min-line") e.data = to;
            });
        },
        maxM(to, from) {
            if (this.minm > this.maxM) this.minm = this.maxM;
            this.graph.graphs.forEach((e) => {
                if (e.name === "max-line") e.data = to;
            });
        },
    },
};
</script>

<style scoped></style>
