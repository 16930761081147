let now = new Date();

const timezoneOffset_ms = now.getTimezoneOffset() * 60000;

const WIDTH = 2000;
const HEIGHT = 1000;

function getDate(n) {
    const date = new Date();
    date.setTime(n + timezoneOffset_ms);
    return date;
}

function displayMinutes(n) {
    const date = getDate(n);

    let hour = date.getHours();
    if (hour < 10) hour = "0" + hour;

    let min = date.getMinutes();
    if (min < 10) min = "0" + min;

    let sec = date.getSeconds();
    if (sec < 10) sec = "0" + sec;

    return ":" + min + ":" + sec;
}

function displaySeconds(n) {
    const date = getDate(n);
    let sec = date.getSeconds();
    if (sec < 10) sec = "0" + sec;
    return ":" + sec;
}

function timespanSlices(timefrom_ms, timespan_ms, unit, mark, step, formatter) {
    const a = [];

    // there is a pre-field, from teh friction of the first step
    const slot = WIDTH / timespan_ms;
    const width = slot * step;
    const pres = timefrom_ms % step;
    const prew = width - slot * pres;

    {
        // this is the scope for the first friction / or the first element
        const o = {};
        const t = timefrom_ms - pres;
        o.t = t;
        o.title = displaySeconds(t);
        o.width = width; //prew;
        o.even = t % mark === 0;
        o.x = prew - width;
        if (t % unit === 0) o.label = formatter(t);
        a.push(o);
    }

    let x = prew;
    for (let i = 0; i <= timespan_ms; i += step) {
        const o = {};
        const t = timefrom_ms + i + step - pres;
        o.t = t;
        o.title = displaySeconds(t);
        o.width = width;
        o.even = t % mark === 0;
        o.x = x;
        if (t % unit === 0) o.label = formatter(t);
        a.push(o);
        x += width;
    }

    return a;
}

export function timespanSections(timefrom_ms, timespan_ms) {
    const gmtime = timefrom_ms - timezoneOffset_ms;

    if (timespan_ms <=  15000) return timespanSlices(gmtime, timespan_ms, 1000, 2000, 1000, displaySeconds);
    if (timespan_ms <=  60000) return timespanSlices(gmtime, timespan_ms, 2000, 2000, 1000, displaySeconds);
    if (timespan_ms <= 120000) return timespanSlices(gmtime, timespan_ms, 5000, 2000, 1000, displaySeconds);

    if (timespan_ms <  300000) return timespanSlices(gmtime, timespan_ms, 10000, 10000, 5000, displaySeconds);

    if (timespan_ms <= 450000) return timespanSlices(gmtime, timespan_ms, 20000, 20000, 10000, displayMinutes);
    if (timespan_ms <= 600000) return timespanSlices(gmtime, timespan_ms, 60000, 20000, 10000, displayMinutes);

    return [];
}
