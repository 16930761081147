import Vue from "vue";

// initial state
const state = {
    sensorlist: [],
};

// mutations
const mutations = {
    SOCKET_UPDATE_SENSORLIST(state, payload) {
        // the only key will be sensorlist
        for (const key in payload) Vue.set(state, key, payload[key]);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
};
