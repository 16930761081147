<template>
        <splitpanes class="default-theme" horizontal :key="key">
            <pane v-for="(line, index) in $store.state.controlpanels[name]" :key="index">
                <splitpanes vertical>
                    <pane v-for="(indication, j) in line" :key="j" :min-size="indication.min_size" :size="indication.size" :max-size="indication.max_size" @click="open()">
                        <component :is="indication.is" :indication="indication" />
                    </pane>
                </splitpanes>
            </pane>
        </splitpanes>
</template>
<script>
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";

import Headline from "@/controlpanel-components/headline.vue";
import ProgressCircular from "@/controlpanel-components/progress-circular.vue";
import Evaluation from "@/controlpanel-components/evaluation.vue";

export default {
    name: "controlpanel",
    components: { Splitpanes, Pane, Headline, ProgressCircular, Evaluation },
    route: { path: "/controlpanel/:name?" },
    data() {
        let name = "default";
        if (this.$route.params.name) name = this.$route.params.name;
        return {
            key: 0,
            name: name,
        };
    },
    methods: {
        open() {
            const uri = this.indication.uri;
            if (uri) if (this.$route.path !== uri) this.$router.push(uri);
        },
    },
    watch: {
        "$route.params.name"(to, from) {
            if (this.$route.params.name !== this.name) {
                this.name = this.$route.params.name;
                this.key++;
            }
        },
    },
};
</script>
