const Ł = console.log;

import * as timelib from "./beacon-timelib.d3.js";
import render_beacon_background from "./beacon-background.d3.js";
import render_beacon_graph from "./beacon-graph.d3.js";

const WIDTH = 2000;
const HEIGHT = 1000;

// demo/dev value
const default_graph = {
    max: 2,
    min: -2,
    graphs: [
        {
            data: [],
            color: "#f00",
            name: "AccelerationX",
        },
        {
            data: 0,
            color: "#040",
            name: "AccelerationY",
        },
        {
            data: 1,
            color: "#004",
            name: "AccelerationZ",
        },
    ],
};

const default_timespan = 600;

for (const i of [14, 15, 17, 18, 19, 26, 28, 33, 57, 57, 76, 98, 120, 210, 330, 420, 570, 760, 820, 1200, 1400, 1600, 1700, 1900, 2400, 4000, 4300, 4600].reverse()) {
    default_graph.graphs[0].data.push([new Date().getTime() - i * 100, Math.random()]);
}

export default function timeline_d3(that) {
    if (!that) {
        console.log("using local data");
        that = {};
    }

    const initdate = new Date();
    let periodicity = 0;

    function reset() {
        const graph = that.graph || default_graph;

        const timeline_d3 = d3.select("#beacon-d3");

        timeline_d3.selectAll("svg").remove();

        const svg = timeline_d3.append("svg");

        svg.attr("viewBox", "0 0 " + WIDTH + " " + HEIGHT)
            .style("background-color", "lightgray")
            .style("cursor", "default");

        svg.append("svg").attr("id", "beacon-background");
        const graphs = svg.append("svg").attr("id", "beacon-graphs");
    }

    function render() {
        periodicity = 0;
        
        const timespan_ms = that.timespan * 1000 || default_timespan * 1000;
        const startdate = that.freezdate || new Date();

        const graph = that.graph || default_graph;

        // take some defaults in case we develop outside of vue
        const timefrom_ms = Math.floor(startdate.getTime() - timespan_ms);

        render_beacon_background(timefrom_ms, timespan_ms);

        const selections = d3
            .select("#beacon-graphs")
            .selectAll("svg")
            .data(graph.graphs, (d) => d.name);
        selections.exit().remove();
        selections
            .enter()
            .append("svg")
            .attr("id", (d) => d.name)
            .append("path");

        for (const g of graph.graphs) render_beacon_graph(timefrom_ms, timespan_ms, graph.min, graph.max, g);
        

    }

    function timer() {

        const timespan = that.timespan || default_timespan;
        periodicity++;

        if (timespan <= 60) return render();
        
        if (timespan <= 120) if (periodicity > 2) return render();
        else return;
        
        if (timespan <= 300) if (periodicity > 3) return render();
        else return;
        
        if (timespan <= 450) if (periodicity > 4) return render();
        else return;
        
        if (timespan <= 600) if (periodicity > 5) return render();
        else return;

        
        render();
    }

    reset();
    render();

    if (!that.startdate) setInterval(timer, 16);

    return {
        reset: reset,
        render: render,
    };
}
