<template>
    <v-card style="min-width: 300px; min-height: 700px; max-width: 1000px; margin: auto; position: relative">
        <v-card-title class="headline card-title">
            ##&en Photos ##&hu Fényképek ## <v-spacer></v-spacer><v-btn color="primary" text @click="openDialog"><v-icon> mdi-folder </v-icon></v-btn>
        </v-card-title>

        <v-card flat style="min-width: 300px; min-height: 700px; max-width: 500px; margin: auto; position: relative">
            <div v-for="(item, i) in $store.state.session.photo_items" :key="i">
                <v-card @click="" hover class="ma-5" style="max-width: 450px; width: 500px; margin: auto" :color="item.selected ? 'primary' : ''" @click.native="onItemTap(item)">
                    <v-card-title class="text-center" style="display: block">{{ item.name || item.key }}</v-card-title>
                </v-card>
            </div>
        </v-card>
        <v-dialog v-model="dialog" max-width="600">
            <v-card>
                <vuetifiles_upload :path="'session/' + $store.state.session.randomid" v-model="vuetifiles" />
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import vuetifiles_upload from "@/vuetifiles-components/vuetifiles-upload.vue";

export default {
    name: "f0x_photo",
    data() {
        return {
            vuetifiles: [],
            dialog: false,
        };
    },
    methods: {
        openDialog() {
            this.dialog = true;
        },
        onItemTap(item) {
            this.$store.commit("session/toggleSelection", item);
        },
    },
    components: {
        vuetifiles_upload,
    },
    watch: {
        vuetifiles: function (vuetifiles) {
            this.$store.dispatch("session/addPhoto", { files: vuetifiles });
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-btn {
    text-transform: none !important;
}

.action-button {
    font-size: 20px;
}

.card-title {
    margin-bottom: 10px;
}
</style>
