//import * as d3 from "d3";

import * as timelib from "./timeline-timelib.d3.js";

export default function ({ timefrom, timespan, total_height, dataset, width, height, selector }) {
    function setPos(d, i) {
        return "translate(0," + i * height + ")";
    }

    const sections_data = timelib.timespanSections(timefrom, timespan, width);

    const selections = d3
        .select(selector)
        .selectAll("g")
        .data(sections_data, (d) => d.t + "@" + timespan);

    // the remover section
    selections.exit().remove();

    // the update section
    selections.attr("transform", (d) => "translate(" + d.x + ",0)");

    // the create section
    const timesections = selections
        .enter()
        .append("g")
        .attr("id", (d) => d.t + "@" + timespan)
        .attr("transform", (d) => "translate(" + d.x + ",0)");

    // create the date text fields
    const tsg = timesections.append("g");
    tsg.append("rect")
        .attr("fill", "lightgray")
        .attr("width", (d) => {
            if (d.label) return 20+d.label.length * 10;
        })
        .attr("height", 40);

    tsg.append("text")
        .attr("y", 20).attr("x", -3)
        .text((d) => {
            if (d.label) return "|" + d.label;
        })
        .attr("class", "title-text")
        .attr("font-family", "Monospace");

    // the group that contains ...
    const sectiongroup = timesections
        .append("svg")
        .attr("width", (d) => d.width)
        .attr("id", (d, i) => "timespan-section" + i);

    // the tooltip title
    sectiongroup.append("svg:title").text((d) => d.title);

    // the marker
    sectiongroup
        .append("rect")
        .attr("y", 40)
        .attr("fill", (d) => {
            //if (d.now) return "#f00";
            if (d.label) return "#000";
            if (d.even === true) return "#888";
            if (d.even === false) return "#fff";
            return "lightgray";
        })
        .attr("width", 3)
        .attr("height", (d) => total_height - 100);

    // the rest of background, to make tooltips visible
    sectiongroup
        .append("rect")
        .attr("y", 40)
        .attr("x", 3)
        .attr("fill", "#ccc")
        .attr("width", (d) => d.width - 3)
        .attr("height", (d) => total_height - 100);
}
