import Vue from "vue";

const state = {};

const mutations = {
    SOCKET_UPDATE_CONTROLPANELS(state, payload) {
        for (let s in payload) Vue.set(state, s, payload[s]);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
};
