import Vue from "vue";

const state = {};

const mutations = {
    SOCKET_UPDATE_TIMELINES(state, payload) {
        //console.log("update-timelines", payload);
        for (let s in payload) Vue.set(state, s, payload[s]);
    },
};
const getters = {
    // Access with $store.getters["database/getDatabaseList"]
    getParameters: (state, getters) => (name) => {
        return state[name];
    },
};
export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
