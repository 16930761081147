<template>
    <v-card flat id="timeline-card">
        <keyboard-events v-on:keydown="keyboardEvent"></keyboard-events>
        <database-document-dialog v-if="db" :db="db" :document="document" v-model="dialog" :key="key" />
        <div id="timeline">
            <div id="timeline-d3" />
            <div id="timehead-d3" />
        </div>
        <v-bottom-navigation id="timeline-bottom-naviugation" fixed background-color="primary">
            <v-btn @click="zoomSreen(-250)" :disabled="width >= 5000">
                <v-icon dark> mdi-magnify-plus-cursor </v-icon>
            </v-btn>
            <v-btn @click="zoomSreen(250)" :disabled="width <= 500">
                <v-icon dark> mdi-magnify-minus-cursor </v-icon>
            </v-btn>
            <v-btn>
                <date-selector v-model="startdate" v-on:update:date="update_by_date()" />
            </v-btn>
            <v-btn @click="startStopClick()">
                <v-icon dark> {{ playing ? "mdi-stop-circle" : "mdi-play-circle" }} </v-icon>
            </v-btn>

            <v-spacer></v-spacer>
            <v-btn @click="zoomTime(1.28)" :disabled="timespan < 10">
                <v-icon dark> mdi-magnify-plus </v-icon>
            </v-btn>
            <v-btn @click="zoomTime(0.8)" :disabled="timespan > 200000000">
                <v-icon dark> mdi-magnify-minus </v-icon>
            </v-btn>
            <v-select dense style="max-width: 150px" :items="timespan_items" v-model="timespan" solo @change="update()"></v-select>
            <v-btn @click="zoomHeight(1.25)">
                <v-icon dark> mdi-magnify-plus-outline </v-icon>
            </v-btn>
            <v-btn @click="zoomHeight(0.8)" :disabled="height < 40">
                <v-icon dark> mdi-magnify-minus-outline </v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="editable_by_dragging" @click="toggleDraggable()">
                <v-icon dark> {{ draggable ? "mdi-arrow-left-right" : "mdi-arrow-horizontal-lock" }} </v-icon>
            </v-btn>
            <v-btn v-if="editable_by_click" class="mx-2" fab dark color="primary" @click="toggleEditable()">
                <v-icon dark>{{ editable ? "mdi-pencil" : "mdi-pencil-off" }} </v-icon>
            </v-btn>

            <v-btn v-if="editable_by_click" class="mx-2" fab dark color="primary" @click="edit()" :disabled="!selected">
                <v-icon dark> mdi-pencil-box </v-icon>
            </v-btn>

            <v-btn v-if="editable_by_click" class="mx-2" fab dark color="primary" @click="add()">
                <v-icon dark> mdi-plus </v-icon>
            </v-btn>
        </v-bottom-navigation>
    </v-card>
</template>

<script>
import keyboardEvents from "@/keyboard-events.vue";
import timeline_d3 from "@/timeline-d3/timeline.d3.js";

import DateSelector from "@/timeline-components/timeline-date-selector.vue";
const lang_locale = "##&en en-GB ##&hu hu-HU ##";

import DatabaseDocumentDialog from "@/database-components/database-document-dialog.vue";
import TimelineTimespanList from "@/timeline-components/timeline-timespan-list.mjs";
import getDataset from "@/timeline-components/getDataset.mjs";
import dragDatagram from "@/timeline-components/dragDatagram.mjs";
import getColor from "@/timeline-components/getColor.mjs";

export default {
    name: "timeline-component",
    route: { path: "/timeline/:name?" },
    components: { DateSelector, DatabaseDocumentDialog, keyboardEvents /*TimelineViewlistDialog*/ },
    data() {
        const timespan = 604800;
        const startdate = new Date(new Date().getTime - (timespan / 2) * 1000);
        return {
            // this object represents all data and parameters
            dataset: [],
            id: null, // the id of the defining database document
            // the start values are based on these parameters, can be overriden with socket vuex defaults
            timespan: timespan,
            startdate: startdate,
            width: 2000,
            height: 100,
            timefrom: Math.floor(startdate.getTime() / 1000),
            // variables set per-timeline
            editable_by_dragging: false,
            editable_by_click: false,
            click_for_database_dialog: false,
            click_for_uri_redirect: false,
            // static
            timespan_items: TimelineTimespanList(),

            // internal usage variables
            loaded: false,
            timeline_d3: null,
            playing: false,
            selected: null,
            draggable: false,
            editable: true,
			// editing
            db: null,
            document: null,
            dialog: false,
            key: 0,

        };
    },
    mounted() {
        //console.log("mounted:", this.$route.params.name);

        this.timeline_d3 = timeline_d3(this);
        const fn = this.timeline_d3.timer;

        this.interval = setInterval(fn, 990);

        this.$store.subscribe((mutation, state) => {
            if (mutation.type !== "timelines/SOCKET_UPDATE_TIMELINES") return;
            if (mutation.payload[this.$route.params.name] === undefined) return;
            this.applyTimelineUpdate();
        });
        this.applyTimelineUpdate();
    },
    methods: {
        applyTimelineUpdate() {
            // get the data
            const timeline = this.$store.state.timelines[this.$route.params.name];

            if (!timeline) return;
            this.db = timeline.db;
            this.id = timeline.id;
            
            this.dataset = deepCopy(timeline.dataset);

            const methods = timeline.methods;
            for (const key in methods) this[key] = methods[key];

            if (this.loaded) return this.timeline_d3.render();
            //this.update();

            const defaults = timeline.default;
            for (const key in defaults) this[key] = defaults[key];
            this.loaded = true;

            //this.update();
            this.timeline_d3.render();
        },
        startStopClick() {
            this.playing = !this.playing;
            if (!this.playing) return;
            this.startdate = new Date();
            this.timeline_d3.timer();
        },
        toggleDraggable() {
            this.draggable = !this.draggable;
            if (this.draggable) this.editable = false;
            this.render();
        },
        toggleEditable() {
            this.editable = !this.editable;
            if (this.editable) this.draggable = false;
            this.render();
        },

        getColor,
        update() {
            if (this.timeline_d3) this.timeline_d3.render("update " + this.$route.params.name);
        },
        update_by_date() {
            this.timefrom = Math.floor(this.startdate.getTime() / 1000 - this.timespan / 2);
            this.update();
        },
        clickOnElement(element) {
            //console.log("Vue: clickOnElement", element);
            this.selected = element;
            this.update();
            if (!this.editable) return; 
            if (this.click_for_database_dialog) {
                if (!element._db) return alert("No database on element");
                if (!element._id) return alert("No document _id on element");
                if (!this.$store.getters["database/hasDatabase"](element._db)) return alert("No database " + element._db);
                const document = this.$store.getters["database/getDocumentById"](element._db, element._id);
                if (!document) return alert("No document in the vuex store");
                this.document = document;
                this.db = element._db;
                return this.edit();
            }
            if (this.click_for_uri_redirect) {
                if (!element._uri) return alert("No URI on document");
                this.$router.push(element._uri);
                return;
            }
            return alert("no-action");
        },
        dragDatagram,
        edit() {
            //if (!this.editable) return;
            this.key++;
            this.dialog = true;
        },
        add() {
            /*this.selected = {
                date: new Date((1 + this.timefrom) * 1000),
                duration: this.timespan - 1,
            };*/
            this.key++;
            this.document = {};
            this.dialog = true;
        },
        deselect() {
            this.selected = null;
            this.update();
        },
        onTimelineDragstarted() {
            // called from the d3 instance
            this.playing = false;
        },
        zoomTime(p) {
            const i = this.timespan / p;
            if (i < 100 || i > 315576000) return;
            const center = Math.round(this.timefrom + this.timespan / 2);
            this.timespan = Math.round(this.timespan / p);
            this.timefrom = Math.round(center - this.timespan / 2);
            this.update();
        },
        zoomHeight(p) {
            const h = Math.round(this.height * p);
            if (h < 50 || h > 1000) return;
            this.height = Math.round(this.height * p);
            this.update();
        },
        zoomSreen(p) {
            const np = this.width + p;
            if (np > 5000 || np < 500) return;
            this.width += p;
            this.update();
        },
        shiftTime(d) {
            this.playing = false;
            this.timefrom += (d * this.timespan) / 64;
            this.update();
        },
        keyboardEvent(e) {
            if (this.dialog) return;

            if (e.key === "o") return this.zoomSreen(-250);
            if (e.key === "p") return this.zoomSreen(250);

            if (e.key === "a") return this.zoomTime(2);
            if (e.key === "s") return this.zoomTime(1.25);
            if (e.key === "d") return this.zoomTime(0.8);
            if (e.key === "f") return this.zoomTime(0.5);

            if (e.key === "y") return this.shiftTime(-10);
            if (e.key === "x") return this.shiftTime(-1);
            if (e.key === "ArrowLeft") return this.shiftTime(-1);
            if (e.key === "ArrowRight") return this.shiftTime(1);
            if (e.key === "c") return this.shiftTime(1);
            if (e.key === "v") return this.shiftTime(10);

            if (e.key === "q") return this.zoomHeight(0.25);
            if (e.key === "w") return this.zoomHeight(0.8);
            if (e.key === "-") return this.zoomHeight(0.8);
            if (e.key === "+") return this.zoomHeight(1.25);
            if (e.key === "e") return this.zoomHeight(1.25);
            if (e.key === "r") return this.zoomHeight(4);

            if (e.key === " ") return this.startStopClick();

            //console.log(e);
            //console.log(e.altkey, e.code, e.ctrlKey, e.isComposing, e.key, e.location, e.metaKey, e.repeat, e.shiftKey);
        },
    },
    watch: {
        "$route.params.name": function (nv) {
            if (!nv) return this.timeline_d3.clear();
            this.loaded = false;
            // this is a workaround for some unexplained nasty shit here
            // the route change kind of prevents proper re-rendering
            // we need more then 100ms, or the render simply wont take effect, as if it didnt happen
            setTimeout(
                function () {
                    this.applyTimelineUpdate();
                }.bind(this),
                250
            );
        },
    },
};

// structuredClone is not available
function deepCopy(obj) {
    if (obj === undefined) return undefined;
    if (obj === null) return null;
    if (typeof obj !== "object") return obj;

    if (obj instanceof Date) {
        const copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }
    if (obj instanceof Array) {
        var copy = [];
        for (let i = 0; i < obj.length; i++) copy[i] = deepCopy(obj[i]);
        return copy;
    }
    if (obj instanceof Object) {
        const copy = {};
        for (const attr in obj) if (obj.hasOwnProperty(attr)) copy[attr] = deepCopy(obj[attr]);

        return copy;
    }
    console.log("Unable to copy", obj);
    return {};
}
</script>
<style scoped>
#timeline-card {
    width: 100%;
}
#timeline-d3 {
    background-color: lightgray;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
}
#timeline-actions {
    z-index: 20;
}
#timeline-bottom-naviugation {
    z-index: 20;
}
</style>
