<template>
    <v-card style="min-width: 300px; min-height: 700px; max-width: 1000px; margin: auto; position: relative">
        <v-card-title class="headline card-title"> Settings </v-card-title>

        <qrcode-vue :value="getCode()" :size="size" level="H" />
        <pre>{{ getCode().split("|").join("\n") }}</pre>
        <v-btn class="ma-2" outlined href="/fox-debug.apk" target="_blank">##&en Download Android debug APK ##&hu Android debug APK letöltése ##</v-btn>
        <v-btn class="ma-2" outlined href="/fox-release.apk" target="_blank">##&en Download Android release APK ##&hu Android release APK letöltése ##</v-btn>

        <v-container fluid>
            <v-checkbox v-for="(v, k, i) in checkboxes" v-model="checkboxes[k]" :label="k" dense :key="i"></v-checkbox>
        </v-container>
    </v-card>
</template>

<script>
import QrcodeVue from "qrcode.vue";

export default {
    name: "f0x_settings",
    data() {
        return {
            size: 300,
            checkboxes: {
                //use_actions_tab: true,
                use_image_tab: true,
                use_camera_photo: true,
                //use_camera_barcode: true,
                use_nfc: false,
                use_ble: false,
                use_settings: true,
                use_logs: false,
            },
            accesscode: null,
        };
    },
    mounted() {
        this.$socket.client.emit("get-fox-passcode", (passcode) => {
            this.accesscode = "$F0X://" + "HOSTNAME=" + ß.HOSTNAME + "|PASSPORT=" + this.$store.state.server.session.user._id + "|PASSCODE=" + passcode;
        });
    },
    methods: {
        getCode() {
            if (!this.accesscode) return "";
            let code = this.accesscode;
            Object.keys(this.checkboxes).forEach((k) => {
                if (this.checkboxes[k] === false) code += "|" + k.toUpperCase() + "=false";
                if (this.checkboxes[k] === true) code += "|" + k.toUpperCase() + "=true";
            });
            return code;
        },
    },
    components: {
        QrcodeVue,
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
