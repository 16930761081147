
import * as timelib from "./beacon-timelib.d3.js";

const WIDTH = 2000;
const HEIGHT = 1000;
const offset = 40;

export default function beacon_graph(timefrom_ms, timespan_ms, min, max, { data, color, name }) {
        
    // convert the array with timestamp and value, to pixel coordinates.
    function getX(t) {
        return ((t - timefrom_ms) / timespan_ms) * WIDTH;
    }

    function getY(v) {
        return offset + ((HEIGHT - offset) / (max - min)) * (max - v);
    }

    const selections = d3.select("#" + name).select("path");


    if (Array.isArray(data)) {
        const path_data = data.map((a) => [getX(a[0]), getY(a[1])]);
        if (path_data.length > 0) path_data.push([WIDTH, path_data[path_data.length-1][1]]);
        selections.attr("d", d3.line().curve(d3.curveStepAfter)(path_data)).attr("fill", "none").attr("stroke", color).attr("stroke-width", 3);

        return;
    }

    if (typeof data === "number") {
        const Y = getY(data);
        const line_data = [[0, Y], [WIDTH, Y]];
        selections.attr("d", d3.line().curve(d3.curveStepAfter)(line_data)).attr("fill", "none").attr("stroke", color).attr("stroke-width", 1);

        return;
    }
}
