//import * as d3 from "d3";

export default function ({ timefrom, timespan, total_height, width }) {

    const pixeltime = width / timespan;
    const timesec = Math.floor(new Date().getTime() / 1000);
    const timepos = timesec - timefrom;

    if (timepos < 0 || timespan < timepos) return;

    d3.select("#timeline-cursor").selectAll("line")
        //.attr("id", "currenttime-cursor")
        .attr("x1", timepos * pixeltime)
        .attr("y1", 0)
        .attr("x2", timepos * pixeltime)
        .attr("y2", total_height)
        .attr("stroke", "#FF222299");
    
}
