<template>
    <v-card style="min-width: 300px; min-height: 700px; max-width: 1000px; margin: auto; position: relative">
        <v-card-title class="headline card-title">
            {{getTitle()}} <v-spacer></v-spacer><v-btn color="primary" text @click="dialog = true"><v-icon> mdi-refresh </v-icon></v-btn>
        </v-card-title>
		<v-img :src="$store.state.session.image"/>
        <v-dialog v-model="dialog" max-width="600">
            <v-card>
                <vuetifiles_upload :path="'session/' + $store.state.session.randomid" v-model="vuetifiles" />
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import vuetifiles_upload from "@/vuetifiles-components/vuetifiles-upload.vue";

export default {
    name: "f0x_image",
    data() {
        return {
            size: 300,
            dialog: false,
            vuetifiles: [],
        };
    },
    methods: {
       getTitle() {
            const url = this.$store.state.session.image;
            if (!url) return '';
            return url.split("/").pop();
        },
        getImage() {
            const url = this.$store.state.session.image;
            if (!url) return '';
            if (url.startsWith("/")) return url;
            if (url.startsWith("http://") || url.startsWith("https://")) return url;
            return "/" + url;
        },
    },
    components: {
        vuetifiles_upload,
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
