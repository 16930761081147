<template>
    <v-card class="boilerplate-cont" style="min-width: 300px; max-width: 1000px; margin: auto; position: relative">
        <template>
            <v-card>
                <v-tabs v-model="tab" fixed-tabs background-color="primary accent-1" center-active>
                    <v-tab>Actions</v-tab>
                    <v-tab>Image</v-tab>
                    <v-tab>Photo</v-tab>
                    <v-tab>Barcode</v-tab>
                    <v-tab>NFC</v-tab>
                    <v-tab>BLE</v-tab>
                    <v-tab>Session</v-tab>
                    <v-tab>Settings</v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item key="Actions">
                        <actions />
                    </v-tab-item>
                    <v-tab-item key="Image">
                        <fox_image />
                    </v-tab-item>
                    <v-tab-item key="Photo">
                        <photo />
                    </v-tab-item>
                    <v-tab-item key="Barcode">
                        <barcode />
                    </v-tab-item>
                    <v-tab-item key="NFC">
                        <nfc />
                    </v-tab-item>
                    <v-tab-item key="BLE">
                        <ble />
                    </v-tab-item>
                    <v-tab-item key="Session">
                        <session />
                    </v-tab-item>
                    <v-tab-item key="Settings">
                        <settings />
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </template>
    </v-card>
</template>

<script>
//<pre>{{ actions }}</pre>

import actions from "@/f0x-actions.vue";
import fox_image from "@/f0x-image.vue";
import ble from "@/f0x-ble.vue";
import nfc from "@/f0x-nfc.vue";
import barcode from "@/f0x-barcode.vue";
import photo from "@/f0x-photo.vue";
import session from "@/f0x-session.vue";
import settings from "@/f0x-settings.vue";

export default {
    name: "fox",
    route: { path: "/fox/:randomid?/:navigation?", icon: "mdi-tablet-cellphone", navigation: "fox" },
    data() {
        return {
            tab: "Actions",
        };
    },
    async mounted() {
        await this.$store.dispatch("location/getLocation");
        await this.$store.dispatch("session/newSession", { randomid: this.$route.params.randomid || null });
        this.$socket.client.emit("new_commsession", this.$store.state.session.randomid);
        if (this.$route.params.randomid !== this.$store.state.session.randomid) this.$router.push({ path: "/fox/" + this.$store.state.session.randomid });
        //this.checkURI();
    },
    components: { actions, fox_image, photo, barcode, nfc, ble, session, settings },
    /*methods: {
    	checkURI() {
            const navigation = this.$store.getters["session/getNavigation"]();
            if (this.$route.params.navigation) if (this.$store.getters["session/getNavigation"].includes(this.$route.params.db)) this.db = this.$route.params.db;
        
        },
    },
    watch: {
    	"$store.state.session.navigation"(nv) {
        	  this.$router.push({ path: '/fox/' +  this.$store.state.session.randomid + "/" + nv});
        }
    }*/
};
</script>

<style scoped></style>
