<template>
    <v-card style="min-width: 300px; min-height: 700px; max-width: 1000px; margin: auto; position: relative">
        <v-card-title class="headline card-title">
            {{ $store.state.session.headline }} <v-spacer></v-spacer>
            <v-btn color="primary" text @click="tap({}, 'home')"><v-icon> mdi-home </v-icon></v-btn>
            <v-btn color="primary" text @click="tap({}, 'back')"><v-icon> mdi-page-first </v-icon></v-btn>
        </v-card-title>
        <v-card-subtitle>
            {{ $store.state.session.navigation }}
        </v-card-subtitle>
        <v-card flat style="min-width: 300px; min-height: 700px; max-width: 500px; margin: auto; position: relative">
            <v-btn block v-for="(v, k, i) in $store.state.session.actions" :key="i" @click="tap(v, k)" style="text-transform: none !important" class="mt-4">{{ v.text }}</v-btn>
        </v-card>

        <v-dialog v-model="dialog" max-width="600">
            <v-card>
                <v-card-title class="headline card-title">{{ title }}</v-card-title>
                <v-card-subtitle class="text-left">
                    <pre>{{ message }}</pre>
                </v-card-subtitle>

                <v-card-text v-if="dialog_input"> <v-text-field autofocus label="" v-model="text" v-on:keyup.enter="ack()"></v-text-field></v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn v-if="cancelButtonText" @click="dialog = false">
                        {{ cancelButtonText }}
                    </v-btn>
                    <v-btn @click="ack()"> {{ okButtonText }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
//<pre>{{ actions }}</pre>

export default {
    name: "f0x_actions",
    data() {
        return {
            dialog: false,
            title: "",
            message: "",
            okButtonText: "OK",
            cancelButtonText: "Cancel",
            text: "",
            dialog_key: null,
            dialog_input: true,
        };
    },
    methods: {
        goHome() {},
        tap: async function (v, key) {
            if (v.prompt) {
                this.title = v.prompt.title || "Text";
                this.message = v.prompt.message || "";
                this.okButtonText = v.prompt.okButtonText || "OK";
                this.cancelButtonText = v.prompt.cancelButtonText || "##&en Cancel ##&hu Mégse ##";
                this.text = v.prompt.defaultText || "";
                this.dialog_key = key;
                this.dialog_input = true;

                this.dialog = true;

                return;
            }

            const reply = await this.$store.dispatch("session/tap", { key });
            if (!reply.alert) return;

            this.title = reply.alert.title || "READY";
            this.message = reply.alert.message || "";
            this.okButtonText = reply.alert.okButtonText || "OK";
            this.dialog_input = false;
            this.dialog = true;
        },
        ack: async function () {
            this.dialog = false;
            if (this.text.length < 1) return;

            const key = this.dialog_key;
            if (!key) return;
            const text = this.text || "?";
            this.text = ""; 
            const reply = await this.$store.dispatch("session/tap", { key, text });
 
            if (!reply.alert) return;

            this.title = reply.alert.title || "READY";
            this.message = reply.alert.message || "";
            this.okButtonText = reply.alert.okButtonText || "OK";
            this.cancelButtonText = null;

            this.dialog_input = false;
            this.dialog = true;
            
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.v-btn {
    text-transform: none !important;
}

.action-button {
    font-size: 20px;
}

.card-title {
    margin-bottom: 10px;
}
</style>
