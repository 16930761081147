<template>
    <v-container class="display-container" fill-height fluid @click="open()" v-bind:style="indication.style">
        <v-row justify="center">
            <v-progress-circular :rotate="-90" :size="100" :width="20" :value="indication.value" :color="indication.color || 'primary'">
                {{ indication.value }}
            </v-progress-circular>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: "controlpanel-progress",
    props: ["indication"],
    data() {
        return {};
    },
    methods: {
        open() {
            //
            const uri = this.indication.uri;
            if (uri) if (this.$route.path !== uri) this.$router.push(uri);
        },
    },
};
</script>
<style scoped>
.display-text {
}
.display-container {
    cursor: pointer;
}
</style>
