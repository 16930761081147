const Ł = console.log;

//import * as d3 from "d3";

import * as timelib from "./timeline-timelib.d3.js";
import timeline_background from "./timeline-background.d3.js";
import timeline_backrows from "./timeline-backrows.d3.js";
import timeline_content from "./timeline-content.d3.js";
import timeline_cursor from "./timeline-cursor.d3.js";
import timeline_center from "./timeline-center.d3.js";

const document_width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
//const document_height = window.innerHeight|| document.documentElement.clientHeight|| document.body.clientHeight;

// around 2000
const DEFAULT_WIDTH = 2000; //document_width;
// minimum 100
const DEFAULT_HEIGHT = 100;

// demo/dev values
function get_demo_data() {
    const default_data_arr = [];

    for (let i = 0; i < 10; i++) default_data_arr.push({ name: "DEMO " + i, index: i });

    function getStartDate() {
        const date = new Date();
        date.setMinutes(Math.floor(Math.random() * 60));
        date.setMilliseconds(0);
        return date;
    }
    function getDuration() {
        return Math.round(Math.random() * 60 * 20);
    }

    return default_data_arr.map((e) => {
        e.items = [];
        for (let i = 1; i < 10; i++)
            e.items.push({
                name: "Item-" + e.index + "-" + i,
                date: getStartDate(),
                duration: getDuration(),
                color: "#" + (100 + i) + "9",
                z: i,
            });

        return e;
    });
}

// that is this of the vue component, that are based on the server update, and came with socketio and vuex
export default function timeline_d3(that) {
    if (!that) that = {};

    // The displayed data
    // if (!that.dataset) that.dataset = get_demo_data();

    // convert that that to a valid datastructure with defaults

    if (!that.width) that.width = DEFAULT_WIDTH;
    if (!that.height) that.height = DEFAULT_HEIGHT;
    if (!that.timespan) that.timespan = 3600;
    if (!that.timefrom) that.timefrom = Math.floor(new Date().getTime() / 1000 - that.timespan / 2);
    if (!that.dataset) that.dataset = [];
    if (that.playing === undefined) that.playing = false;
    if (that.draggable === undefined) that.draggable = true;

    let grabx = 0;
    let basetime = 0;
    let dragging = false;

    // drag functions in this context are for the time-axis.
    function dragstarted(event, d) {
        dragging = true;
        if (that.onTimelineDragstarted) that.onTimelineDragstarted();
        d3.select(this).style("cursor", "grab");
        grabx = event.x;
        basetime = that.timefrom;
    }

    function dragged(event) {
        const offset = -grabx + event.x;
        d3.select("#timeline-background").attr("x", offset);
        d3.select("#timeline-content").attr("x", offset);
        d3.select("#timeline-cursor").attr("x", offset);

        that.timefrom = basetime - (-grabx + event.x) / (that.width / that.timespan);

        timeline_background({ ...that, ...{ selector: "#timehead-background" } });
    }

    function dragended(event, d) {
        dragging = false;
        // ?
        if (that.onTimelineDragended) that.onTimelineDragended();
        d3.select(this).style("cursor", "default");
        render();
    }
    // .. drag of timescale end.

    function render_timeline() {
        const dataset = that.dataset;
        const total_height = 100 + dataset.length * that.height;
        const timeline_d3 = d3.select("#timeline-d3");

        timeline_d3.selectAll("svg").remove();
        const svg = timeline_d3.append("svg");

        svg.attr("viewBox", "0 0 " + that.width + " " + total_height).style("cursor", "default");

        svg.append("svg").attr("id", "timeline-background");
        svg.append("svg").attr("id", "timeline-backrows").attr("y", 50);
        svg.append("svg").attr("id", "timeline-content").attr("y", 50);
        svg.append("svg").attr("id", "timeline-cursor").append("line");
        
		svg.append("svg").attr("id", "timeline-center").append("line");
        
        timeline_background({ ...that, ...{ selector: "#timeline-background" } });
        timeline_backrows(that);

        timeline_content(that); //{ ...that, ...{ that, render } });

        svg.call(d3.drag().on("start", dragstarted).on("drag", dragged).on("end", dragended));

        var leadinGradient = svg
            .append("defs")
            .append("linearGradient")
            .attr("id", "leadinGradient")
            .attr("x1", "0%")
            .attr("y1", "0%")
            .attr("x2", "0%")
            .attr("y2", "100%")
            .attr("gradientTransform", "rotate(-90)");
        leadinGradient.append("stop").attr("offset", "0%").attr("stop-color", "black").attr("stop-opacity", 1);
        leadinGradient.append("stop").attr("offset", "30%").attr("stop-color", "white").attr("stop-opacity", 1);
        leadinGradient.append("stop").attr("offset", "100%").attr("stop-color", "white").attr("stop-opacity", 0);

        var leadoutGradient = svg
            .append("defs")
            .append("linearGradient")
            .attr("id", "leadoutGradient")
            .attr("x1", "0%")
            .attr("y1", "0%")
            .attr("x2", "0%")
            .attr("y2", "100%")
            .attr("gradientTransform", "rotate(-90)");
        leadoutGradient.append("stop").attr("offset", "0%").attr("stop-color", "white").attr("stop-opacity", 0);
        leadoutGradient.append("stop").attr("offset", "70%").attr("stop-color", "white").attr("stop-opacity", 1);
        leadoutGradient.append("stop").attr("offset", "100%").attr("stop-color", "black").attr("stop-opacity", 1);
    }

    function render_timehead() {
        const total_height = 50;

        const timehead_d3 = d3.select("#timehead-d3");
        timehead_d3.selectAll("svg").remove();
        const svg = timehead_d3.append("svg");

        svg.attr("viewBox", "0 0 " + that.width + " " + total_height)
            .style("background-color", "gray")
            .style("cursor", "default");

        svg.append("svg").attr("id", "timehead-background");

        timeline_background({ ...that, ...{ selector: "#timehead-background" } });

        svg.call(d3.drag().on("start", dragstarted).on("drag", dragged).on("end", dragended));

        const center = svg
            .append("line")
            .attr("x1", that.width / 2)
            .attr("y1", 25)
            .attr("x2", that.width / 2)
            .attr("y2", 50)
            .style("stroke", "black");
    }

    function clear() {
     	const timeline_d3 = d3.select("#timeline-d3");
        timeline_d3.selectAll("svg").remove();
    }
    
    function render() {

        const timeline_d3 = d3.select("#timeline-d3");
        timeline_d3.selectAll("svg").remove();

        that.total_height = 100 + that.dataset.length * that.height;
        render_timeline();
        render_timehead();
        timeline_center(that);
        if (!dragging) timeline_cursor(that);
    }

    function timer() {
      if (that.playing) {
            that.timefrom = Math.round(new Date().getTime() / 1000 - that.timespan / 2);
            render();
        }
        if (!dragging) timeline_cursor(that);
    }
    
    //setInterval(timer, 1000);
    
    that.render = render;
    
    render();

    return {
        render, clear, timer
    };
}
