import Vue from "vue";

const state = {
    //latitude: 0,
    //longitude: 0,
    //altitude: 0,
};

const mutations = {
    setLocation(state, location) {
        Vue.set(state, "latitude", location.latitude || 0);
        Vue.set(state, "longitude", location.longitude || 0);
        Vue.set(state, "altitude", location.altitude || 0);
        //global.f0x.log("setLocation");
        //console.log(state);
    },
};

const actions = {
    getLocation: async ({ commit, state }) => {
        //console.log("GPS getLocation()");
        return new Promise((resolve) => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    // success function
                    (position) => {
                        //console.log(position.coords);
                        commit("setLocation", position.coords);
                        resolve();
                    },
                    // error function
                    () => {
                        console.log("There was a problem retrieveng the location");
                        resolve();
                    },
                    // options argument
                    {
                        enableHighAccuracy: true,
                        timeout: 5000,
                        maximumAge: 0,
                    }
                );
            } else {
                console.log("Geolocation is not supported by this browser.");
                resolve();
            }
        });
    },
};

const getters = {
    getLocation(state) {
        return {
            latitude: state.latitude,
            longitude: state.longitude,
            altitude: state.altitude,
        };
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
