
export default function ({ total_height, width }) {

    d3.select("#timeline-center")
        .selectAll("line")
        //.attr("id", "currenttime-cursor")
        .attr("x1", width/2)
        .attr("y1", 0)
        .attr("x2", width/2)
        .attr("y2", total_height)
        .attr("stroke", "#22222222");
    
}
