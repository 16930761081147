<template>
    <v-card style="min-width: 300px; min-height: 700px; max-width: 1000px; margin: auto; position: relative">
        <v-card-title class="headline card-title">
            Session <v-spacer></v-spacer><v-btn color="primary" text @click="newSession"><v-icon> mdi-refresh </v-icon></v-btn>
        </v-card-title>

        <qrcode-vue :value="'$FOX://SESSION=' + $store.state.session.randomid" :size="size" level="H"/>
        <pre>$FOX://SESSION={{ $store.state.session.randomid }}</pre>
    </v-card>
</template>

<script>
import QrcodeVue from "qrcode.vue";

export default {
    name: "f0x_session",
    data() {
        return {
            size: 300,
        };
    },
    methods: {
        newSession() {
            this.$store.dispatch("location/getLocation");
            this.$store.dispatch("session/newSession", { randomid: null});
            this.$socket.client.emit('new_commsession', this.$store.state.session.randomid);
            this.$router.push({ path: '/fox/' +  this.$store.state.session.randomid});
        },
    },
    components: {
        QrcodeVue,
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
