// this is an automatically generated file from the ßoilerplate framework for app

// /srv/codepad-project/@vue-modules/app/vue;
import dev from '@/app.main.dev.js';

// /srv/codepad-project/@vue-modules/d3/vue;
import d3 from '@/app.main.d3.js';

// /srv/codepad-project/@vue-modules/fontawesome5/vue;
import fontawesome from '@/app.main.fontawesome.js';

// /srv/codepad-project/@vue-modules/socketio/vue;
import socketio from '@/app.main.socketio.js';

// /srv/codepad-project/@vue-modules/vuetify/vue;
import vuetify from '@/app.main.vuetify.js';


export default { dev, d3, fontawesome, socketio, vuetify,  };