<template>
    <v-container fill-height dark>
        <v-layout>
            <v-row justify="center">
                <v-col xs="12" sm="6" md="4" lg="3" xl="2" cols="12" class="pa-5 desc-cols" v-for="s in $store.state.sensors.sensorlist" :key="s.id">
                    <v-card height="150" width="260" class="pa-2" @click.native="show(s)">
                        <v-btn class="mx-2 ma-3" fab :color="get_color(s)"><v-icon>mdi-hvac</v-icon></v-btn>
                        <v-card-title class="text-center" style="display: block">{{ s.name || s.id }}</v-card-title>
                    </v-card>
                </v-col>
            </v-row>
        </v-layout>
    </v-container>
</template>
<script>
export default {
    name: "sensors",
    route: { path: "/sensors", icon: "mdi-hvac", navigation: "sensors" },
    methods: {
        get_color(e) {
            if (e.online === true) return "green";
            if (e.online === false) return "red";
            return "gray";
        },
        show(s) {
            this.$router.push("/beacon/" + s.id);
        },
    },
};
</script>

<style scoped>
.sensor-card {
    padding: 20px;
    max-width: 800px;
    min-width: 400px;
}
</style>
