import Vue from "vue";
import axios from "axios";

// initial state generator
function newSession() {
    return {
        randomid: Math.random().toString(36).substr(2, 9).toUpperCase(),
        actions: {},
        headline: "Connecting ...",
        navigation: "actions",
        ble_items: [],
        barcode_items: [],
        photo_items: [],
        nfc_items: [],
        image: null
    };
}

const state = newSession();

const mutations = {
    // set state values
    set(state, o) {
        //console.log('set', o);
        for (const p in o) Vue.set(state, p, o[p]);
    },
    // merge with ignored selections
    merge(state, o) {
    	for (const p in o) {
            // arr must be an array, and state[key] must be an array, key must be present in both, and shall not be undefiend
            const merge_arr = o[p];
            const items_arr = state[p];
            const final_arr = merge_arr.map(item => {
                if (typeof item === 'string') item = { key: item };
                if (item.selected !== undefined) return item;
            	let selected = item.selected_default || true;
                for (const i of items_arr) if (i.key === item.key) selected = i.selected;
                item.selected = selected;
                return item;
            });
            Vue.set(state, p, final_arr);
        }
    },
    toggleSelection(state, item) {
        item.selected = !item.selected;
    },
    //****
    
    setName(state, { item, name }) {
        item.name = name;
    },
    setActions(state, actions) {
        state.actions = actions;
    },
    setNavigation(state, navigation) {
        console.log(navigation);
        state.navigation = navigation;
    },

    addBarcodeItem(state, item) {
        state.barcode_items.unshift(item);
    },
    addBleItem(state, item) {
        state.ble_items.unshift(item);
    },
    /*
    setBleItems(state, items) {
        state.ble_items = items;
    },
    addPhotoItem(state, item) {
        state.photo_items.push(item);
    },
    */
    addPhotos(state, items) {
        state.photo_items = items;
    },
    newSession(state, randomid) {
        Object.assign(state, newSession());
        state.randomid = randomid || Math.random().toString(36).substr(2, 9).toUpperCase();
    },

    SOCKET_SESSION_NFCS(state, response) {
        const known_items = Object.keys(response)
            .filter((e) => response[e])
            .map((e) => {
                return {
                    name: response[e],
                    key: e,
                    selected: true,
                };
            });

        const other_items = Object.keys(response)
            .filter((e) => !response[e])
            .map((e) => {
                return {
                    name: e,
                    key: e,
                    selected: false,
                };
            });
        const keys = state.nfc_items.map((e) => e.key);
        for (const known_item of known_items) if (!keys.includes(known_item.key)) state.nfc_items.unshift(known_item);
        for (const other_item of other_items) if (!keys.includes(other_item.key)) state.nfc_items.push(other_item);
    },
    SOCKET_SESSION_BLES(state, response) {
        const known_items = Object.keys(response)
            .filter((e) => response[e])
            .map((e) => {
                return {
                    name: response[e],
                    key: e,
                    selected: true,
                };
            });

        const other_items = Object.keys(response)
            .filter((e) => !response[e])
            .map((e) => {
                return {
                    name: e,
                    key: e,
                    selected: false,
                };
            });
        const keys = state.ble_items.map((e) => e.key);
        for (const known_item of known_items) if (!keys.includes(known_item.key)) state.ble_items.unshift(known_item);
        for (const other_item of other_items) if (!keys.includes(other_item.key)) state.ble_items.push(other_item);
    },
    SOCKET_SESSION_BARCODES(state, response) {
        const known_items = Object.keys(response)
            .filter((e) => response[e])
            .map((e) => {
                return {
                    name: response[e],
                    key: e,
                    selected: true,
                };
            });

        const other_items = Object.keys(response)
            .filter((e) => !response[e])
            .map((e) => {
                return {
                    name: e,
                    key: e,
                    selected: false,
                };
            });
        const keys = state.barcode_items.map((e) => e.key);
        for (const known_item of known_items) if (!keys.includes(known_item.key)) state.barcode_items.unshift(known_item);
        for (const other_item of other_items) if (!keys.includes(other_item.key)) state.barcode_items.push(other_item);
    },
    SOCKET_SESSION_PHOTOS(state, response) {
        const known_items = Object.keys(response).map((e) => {
            return {
                name: response[e],
                key: e,
                selected: true,
            };
        });

        const keys = state.photo_items.map((e) => e.key);
        for (const known_item of known_items) if (!keys.includes(known_item.key)) state.photo_items.unshift(known_item);
    },
};



const actions = {
    post_message: async ({ commit, state, rootGetters, rootState }, payload) => {
        //const { commit, state, rootGetters } = context;
        const location = rootGetters["location/getLocation"];
        const randomid = state.randomid;
        
		const data = { ...payload, randomid,  ...location,};
        
        // items need to be transfered, as the selection property might be important
        const ble_items = state.ble_items.filter(e => e.selected);
        if (ble_items.length > 0) data.ble_items = ble_items;
        const barcode_items = state.barcode_items.filter(e => e.selected);
        if (barcode_items.length > 0) data.barcode_items = barcode_items;
        const photo_items = state.photo_items.filter(e => e.selected);
        if (photo_items.length > 0) data.photo_items = photo_items;
        const nfc_items = state.nfc_items.filter(e => e.selected);
        if (nfc_items.length > 0) data.nfc_items = nfc_items;
        
        let response;
        try {
            response = await axios({
                method: "post",
                url: "/post-f0x-message.json",
                headers: { "Content-Type": "application/json" },
                data
            });
        } catch (err) {
            console.log(err);
        }        
        
        if (!response) return console.log("POST no-response ERROR");
		const reply = response.data;
        console.log(response);
        // process commits
        if (reply.commit) for (const key in reply.commit) commit(key, reply.commit[key]);

        return reply;
    },
    newSession: async ({ commit, dispatch, state, rootGetters, rootState }, { randomid }) => {
        commit("newSession", randomid);

        const payload = {
            message: "new_commsession",
        };

        const reply = await dispatch("post_message", payload);
        return reply;
    },
    tap: async ({ commit, state, dispatch, rootGetters }, { key, text, cb }) => {
        const payload = {  };

        payload.message = "action";
        payload.tap = key;
        payload.text = text;

        payload.bles = state.ble_items.filter((e) => e.selected === true).map((e) => e.key);
        payload.barcodes = state.barcode_items.filter((e) => e.selected === true).map((e) => e.key);
        payload.photos = state.photo_items.filter((e) => e.selected === true).map((e) => e.key);
        payload.nfcs = state.nfc_items.filter((e) => e.selected === true).map((e) => e.key);

        const reply = await dispatch("post_message", payload);
        return reply;

    },
    addBarcode: async ({ commit, state, dispatch, rootGetters }, { text }) => {
        //const o = {};
        //o.name = text;
        //o.format = "manual";
        //o.selected = false;
        
        //commit("addBarcodeItem", o);

        const payload = { message: "barcode", format: "manual", barcode: text };

        const reply = await dispatch("post_message", payload);
        return reply;
    },
    addBleItem({ commit, state, dispatch, rootGetters }, { text }) {
        const o = {};
        o.name = text;
        o.key = text;
        //o.barcode = result.text;
        //o.mac = text;
        o.selected = true;
        commit("addBleItem", o);

        const payload = { message: "BLE", bles: [text] };
        payload.randomid = state.randomid;

        payload.callback = (response) => {
            state.ble_items.forEach((e) => {
                if (response[e.key]) commit("setName", { item: e, name: response[e.key] });
            });
        };

        send(payload);
    },
    addPhoto: async ({ commit, state, dispatch, rootGetters }, { files }) => {
        const payload = { message: "upload", files };

        const reply = await dispatch("post_message", payload);
        return reply;
    }
};

const getters = {
    getNavigation: (state) => () => {
        return state.navigation;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

// deprecated
function send(payload) {
    if (!payload.message) payload.message = "unknown";
    const callback = payload.callback || function () {};

    // POST implementation
    axios({
        method: "post",
        url: "/post-f0x-update.json",
        headers: { "Content-Type": "application/json" },
        data: payload,
    })
        .then(function (response) {
            const data = response.data;
            if (callback) callback(data);
        })
        .catch((error) => {
            console.log("ERROR in session axios POST", error);
            //alert("Network error.");
        });
}
